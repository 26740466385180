<!--模板 公用信息-->
<template>
  <div class="template-base-config g-ui-80">

    <div class="g-ui-layout">
      <div class="g-layout-label is-required">活动名称</div>
      <el-input v-model="activeData.activityName" placeholder="请输入活动名称" :maxlength="30" show-word-limit></el-input>
    </div>

    <div class="g-ui-layout">
      <div class="g-layout-label is-required">活动时间</div>
      <div class="g-layout-value">
        <el-date-picker v-model="activeData.startTime" :clearable="false" type="datetime" placeholder="请选择开始时间" format="yyyy-MM-dd HH:mm"></el-date-picker>
        <span class="g-layout-interval">至</span>
        <el-date-picker v-model="activeData.endTime" :picker-options="disabledDate" :clearable="false" type="date" placeholder="请选择结束时间" format="yyyy-MM-dd HH:mm"></el-date-picker>
      </div>
    </div>

    <div class="g-ui-layout g-ui-layout-baseline">
      <div class="g-layout-label">活动备注</div>
      <a-input v-model="activeData.activityRemark" type="textarea" :auto-size="{ minRows: 3, maxRows: 3 }" placeholder="请输入活动备注" :maxlength="100" show-word-limit></a-input>
    </div>

  </div>
</template>

<script>
export default {
  name: 'templateBaseConfig',
  props: {
    activeData: Object
  },
  data () {
    return {
      disabledDate: {
        disabledDate: this.disabledDateFn,
      }
    }
  },
  methods: {
    disabledDateFn(time) {
      return time.getTime() < this.activeData.startTime.getTime()
    }
  }
}
</script>

<style lang="scss" scoped>
  .template-base-config{
  }
</style>
